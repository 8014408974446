<!-- <div class="container p-4 mx-auto">
    <div class="relative bg-white overflow-x-auto shadow-md sm:rounded-lg">
        <canvas id="barChart"></canvas>
    </div>
</div> -->
<div class="p-4 bg-gray-100 min-h-screen">
  <!-- Summary Cards Row -->
  <div class="container space-y-6"> 
    <!-- Top Cards Row -->
    <div class="flex flex-wrap gap-1" style="justify-content: space-evenly">
      <!-- Card 1 -->
      <!-- <div  *ngIf="roles != 'ADMINOFFICER'" 
        style="background: linear-gradient(to bottom, #00d2ff, #3a7bd5)"
        class="flex justify-between items-center p-6 rounded-xl shadow-lg text-white transition-all transform hover:scale-105 duration-300 sm:w-[calc(25%-1rem)]"
      >
        <div>
          <h2 class="text-lg font-semibold">Total Stations</h2>
          <p class="text-3xl font-bold mt-2">
            {{ stationStats?.totalStations }}
          </p>
        </div>
        <i class="fas fa-map-marker-alt text-3xl opacity-80"></i>
      </div> -->

      <!-- Card 2 (click="openAssetDialog()"-->
       
      
<!--       
      <div  *ngIf="roles != 'ADMINOFFICER'" 
        (click)="downloadTotalAsset()"
        style="background: linear-gradient(to top, #fc4a1a, #f7b733)"
        class="flex justify-between items-center p-6 rounded-xl shadow-lg text-white transition-all transform hover:scale-105 duration-300 cursor-pointer sm:w-[calc(25%-1rem)]"
      >
        <div>
          <h2 class="text-lg font-semibold">Total Assets</h2>
          <p class="text-3xl font-bold mt-2">{{ totalAssertsList.length }}</p>
        </div>
        <i class="fas fa-box text-3xl opacity-80"></i>
      </div> -->

      <!-- Card 4 -->
      <!-- <div (click)="downloadMovableAsset()"  *ngIf="roles != 'ADMINOFFICER'" 
        style="background: linear-gradient(to top, #7f00ff, #e100ff)"
        class="flex justify-between items-center p-6 rounded-xl shadow-lg text-white transition-all transform hover:scale-105 duration-300 sm:w-[calc(25%-1rem)]"
      >
        <div>
          <h2 class="text-lg font-semibold">Movable</h2>
          <p class="text-3xl font-bold mt-2">{{ movableAssets.length }}</p>
        </div>
        <i class="fas fa-box text-3xl opacity-80"></i>
      </div> -->

      <!-- Card 5 -->
      <!-- <div  *ngIf="roles != 'ADMINOFFICER'" 
        style="background: linear-gradient(to bottom, #ff0099, #493240)"
        class="flex justify-between items-center p-6 rounded-xl shadow-lg text-white transition-all transform hover:scale-105 duration-300 sm:w-[calc(20%-1rem)]"
      >
        <div>
          <h2 class="text-lg font-semibold">Immovable</h2>
          <p class="text-3xl font-bold mt-2">{{ totalAssertsList.length-movableAssets.length }}</p>
        </div>
        <i class="fas fa-building text-3xl opacity-80"></i>
      </div>
    </div> -->



<div class="p-4 bg-gray-100 min-h-screen">
  <!-- Summary Cards Row -->
  <div class="container space-y-6">
    <!-- Top Cards Row -->
    <div class="flex flex-wrap gap-1" style="justify-content: space-evenly">
      <!-- Card 1 -->
      <div
      *ngIf="roles !== 'SYSADMIN'"         style="background: linear-gradient(to bottom, #00d2ff, #3a7bd5)"
        class="flex justify-between items-center p-6 rounded-xl shadow-lg text-white transition-all transform hover:scale-105 duration-300 sm:w-[calc(20%-1rem)]"
      >
        <div>
          <h2 class="text-lg font-semibold">User Stations</h2>
          <p class="text-3xl font-bold mt-2">
            {{ stationStats?.totalStations }}
          </p>
        </div>
        <i class="fas fa-map-marker-alt text-3xl opacity-80"></i>
      </div> 

      


    
    <div (click)="downloadUserAssertGroupedByStation()"
    *ngIf="roles !== 'SYSADMIN'"     style="background: linear-gradient(to top, #7f00ff, #e100ff)"
      class="flex justify-between items-center p-6 rounded-xl shadow-lg text-white transition-all transform hover:scale-105 duration-300 sm:w-[calc(20%-1rem)]"
    >
      <div>
        <h2 class="text-lg font-semibold">User Asserts</h2>
        <p class="text-3xl font-bold mt-2">
        {{  userAssertsGroupedByStations.length }}
        </p>
      </div>
      <i class="fas fa-map-marker-alt text-3xl opacity-80"></i>
    </div> 

  <!-- Card 4 -->
  <div (click)="downloadMovableAsset()"  *ngIf="roles !== 'SYSADMIN'"      style="background: linear-gradient(to bottom, #00d2ff, #3a7bd5)"

  class="flex justify-between items-center p-6 rounded-xl shadow-lg text-white transition-all transform hover:scale-105 duration-300 sm:w-[calc(20%-1rem)]"
>
  <div>
    <h2 class="text-lg font-semibold">Movable </h2>
    <p class="text-3xl font-bold mt-2">{{ movableAssets.length }}</p>
  </div>
  <i class="fas fa-box text-3xl opacity-80"></i>
</div>
      <!-- Card 5 -->
      <div  *ngIf="roles !== 'SYSADMIN'" 
        style="background: linear-gradient(to bottom, #ff0099, #493240)"
        class="flex justify-between items-center p-6 rounded-xl shadow-lg text-white transition-all transform hover:scale-105 duration-300 sm:w-[calc(20%-1rem)]"
      >
        <div>
          <h2 class="text-lg font-semibold">Immovable</h2>
          <p class="text-3xl font-bold mt-2">{{ totalAssertsList.length-movableAssets.length }}</p>
        </div>
        <i class="fas fa-building text-3xl opacity-80"></i>
      </div>


    <!-- Card 3 -->
    <div  *ngIf="roles !== 'SYSADMIN'" 
      style="background: linear-gradient(to top, #11998e, #38ef7d)"
      class="flex justify-between items-center p-6  rounded-xl shadow-lg text-white transition-all transform hover:scale-105 duration-300 sm:w-[calc(25%-1rem)]"
    >
      <div>
        <h2 class="text-lg font-semibold">Distribution</h2>
        <p class="text-3xl font-bold mt-2">{{ getAssetDistribution() }}</p>
      </div>
      <i class="fas fa-chart-pie text-3xl opacity-80"></i>
    </div>
    <!-- Spacer to center align the second row -->
    <div class="hidden sm:block w-[calc(33.333%-1rem)]"></div>

    <!-- Charts Section -->
    <div class="container mx-auto px-4 mt-8">
      <!-- Charts Grid -->
      <div class="space-y-6">
        <!-- Bar Chart -->
        <!-- <div class="bg-white rounded-xl shadow-lg overflow-hidden">
          <div class="p-6 border-b border-gray-100">
            <h2 class="text-xl font-semibold text-gray-800">
              Total Assets per Station
            </h2>
          </div>
          <div class="p-6">
            <div class="h-[400px] w-full">
              <canvas id="horizontalBarChart"></canvas>
            </div>
          </div>
        </div> -->

        <!-- Asset Type Distribution Chart -->
        <!-- <div class="w-full bg-white rounded-xl shadow-lg p-4 mb-6">
          <canvas #assetTypeChartCanvas class="w-full h-96"></canvas>
        </div> -->

        <!-- Bubble Chart -->
        <!-- <div class="bg-white rounded-xl shadow-lg overflow-hidden">
          <div class="p-6 border-b border-gray-100">
            <h2 class="text-xl font-semibold text-gray-800">
              Asset Size and Distribution
            </h2>
          </div>
          <div class="p-6">
            <div class="h-[400px] w-full">
              <canvas id="bubbleChart"></canvas>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>

<!-- Asset Selection Dialog -->
<dialog
  #assetDialog
  class="rounded-xl shadow-2xl p-0 w-full max-w-5xl mx-auto backdrop:bg-gray-900/50"
  (click)="onDialogClick($event)"
>
  <div class="bg-white rounded-xl">
    <!-- Dialog Header -->
    <div
      class="p-6 border-b bg-gradient-to-r from-blue-600 to-blue-800 rounded-t-xl"
    >
      <div class="flex justify-between items-center">
        <h3 class="text-2xl font-semibold text-white">Asset Details</h3>
        <button
          (click)="closeDialog()"
          class="text-white hover:text-gray-200 transition-colors"
        >
          <i class="fas fa-times text-xl"></i>
        </button>
      </div>
    </div>

    <!-- Selection Form -->
    <div class="p-6">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <!-- Province Selection -->
        <div
          *ngIf="provinces.length === 0"
          class="text-center p-6 bg-gray-50 rounded-lg"
        >
          <i
            class="fas fa-exclamation-triangle text-yellow-500 text-4xl mb-4"
          ></i>
          <p class="text-gray-600 text-lg">
            No provinces found. Please check your connection or contact support.
          </p>
        </div>

        <div
          *ngIf="provinces.length > 0 && stations.length === 0"
          class="text-center p-6 bg-gray-50 rounded-lg"
        >
          <i class="fas fa-map-marker-alt text-blue-500 text-4xl mb-4"></i>
          <p class="text-gray-600 text-lg">
            No stations found for the selected province.
          </p>
        </div>

        <div
          *ngIf="provinces.length > 0"
          class="md:col-span-2 max-w-md mx-auto w-full"
        >
          <label class="block text-sm font-medium text-gray-700 mb-2"
            >Select Province</label
          >
          <select
            class="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            [(ngModel)]="selectedProvince"
            (ngModelChange)="selectProvince($event)"
          >
            <option *ngFor="let province of provinces" [value]="province.name">
              {{ province.name }}
            </option>
          </select>
        </div>

        <!-- Station Selection -->
        <div
          *ngIf="stations.length > 0"
          class="md:col-span-2 max-w-md mx-auto w-full"
        >
          <label class="block text-sm font-medium text-gray-700 mb-2"
            >Select Station</label
          >
          <select
            class="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            [(ngModel)]="selectedStation"
            (ngModelChange)="selectStation($event)"
            [disabled]="stations.length === 0"
          >
            <option value="" disabled>Select a Station</option>
            <option *ngFor="let station of stations" [ngValue]="station">
              {{ station.stationName }}
            </option>
          </select>
        </div>
      </div>
      <!-- Asset Grid -->
      <div
        *ngIf="filteredAssets.length === 0 && selectedStation"
        class="text-center p-6 bg-gray-50 rounded-lg md:col-span-2"
      >
        <i class="fas fa-box-open text-gray-400 text-4xl mb-4"></i>
        <p class="text-gray-600 text-lg">No assets found for this station.</p>
        <p class="text-gray-500 text-sm mt-2">
          Try selecting a different station or contact support.
        </p>
      </div>

      <!-- Asset Grid -->
      <div *ngIf="filteredAssets.length > 0" class="space-y-4">
        <div class="flex justify-between items-center px-4">
          <div class="text-gray-600">
            Showing {{ filteredAssets.length }} of {{ totalElements }} assets
          </div>
          <div class="flex items-center space-x-2">
            <label class="text-sm text-gray-600 whitespace-nowrap"
              >Assets per page:</label
            >
            <div class="relative">
              <select
                [(ngModel)]="pageSize"
                (ngModelChange)="changePageSize(pageSize)"
                class="appearance-none w-full pl-3 pr-8 py-1 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option [value]="10">10</option>
                <option [value]="25">25</option>
                <option [value]="50">50</option>
                <option [value]="100">100</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-h-[60vh] overflow-y-auto p-4 custom-scrollbar"
        >
          <div
            *ngFor="let asset of filteredAssets"
            class="bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden"
          >
            <!-- Card Header with Icon and Status -->
            <div class="p-4 bg-gradient-to-r from-gray-50 to-gray-100 border-b">
              <div class="flex items-center justify-between">
                <div class="flex items-center space-x-3">
                  <div
                    class="w-12 h-12 rounded-full bg-blue-100 flex items-center justify-center"
                  >
                    <i
                      class="fas {{
                        getAssetIcon(asset.assertType)
                      }} text-blue-600 text-xl"
                    ></i>
                  </div>
                  <div>
                    <h3 class="font-semibold text-lg text-gray-800">
                      {{ asset.assetDisc }}
                    </h3>
                    <p class="text-sm text-gray-500">
                      {{ asset.assertType }}
                    </p>
                  </div>
                </div>
                <span
                  [class]="
                    'px-3 py-1 rounded-full text-sm font-medium ' +
                    (asset.status === 'Active'
                      ? 'bg-green-100 text-green-800'
                      : 'bg-red-100 text-red-800')
                  "
                >
                  {{ asset.status }}
                </span>
              </div>
            </div>

            <!-- Asset Details -->
            <div class="p-4 space-y-4">
              <!-- Basic Info Section -->
              <li>
                <a
                  href="/assert/assetregister"
                  class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  >Assets Register</a
                >
              </li>

              <div class="space-y-2">
                <div class="flex items-center space-x-2 text-gray-600">
                  <i class="fas fa-box-open w-5"></i>
                  <span class="text-sm">Asset Type:</span>
                  <span class="text-sm font-medium text-gray-900">{{
                    asset.assertType || "Not Specified"
                  }}</span>
                </div>
                <div class="flex items-center space-x-2 text-gray-600">
                  <i class="fas fa-truck w-5"></i>
                  <span class="text-sm">Mobility:</span>
                  <span class="text-sm font-medium text-gray-900">{{
                    asset.isMovable ? "Movable" : "Not Movable"
                  }}</span>
                </div>
              </div>

              <!-- Divider -->
              <hr class="border-gray-200" />

              <!-- Details Section -->
              <div class="space-y-2">
                <div class="flex items-center space-x-2 text-gray-600">
                  <i class="fas fa-barcode w-5"></i>
                  <span class="text-sm">Serial Number:</span>
                  <span class="text-sm font-medium text-gray-900">{{
                    asset.serialNumber || "N/A"
                  }}</span>
                </div>
                <div class="flex items-center space-x-2 text-gray-600">
                  <i class="fas fa-file-invoice w-5"></i>
                  <span class="text-sm">Invoice Number:</span>
                  <span class="text-sm font-medium text-gray-900">{{
                    asset.invoiceNumber || "N/A"
                  }}</span>
                </div>
                <div class="flex items-center space-x-2 text-gray-600">
                  <i class="fas fa-hashtag w-5"></i>
                  <span class="text-sm">Asset Number:</span>
                  <span class="text-sm font-medium text-gray-900">{{
                    asset.assetNumber || "N/A"
                  }}</span>
                </div>
                <!-- <div class="flex items-center space-x-2 text-gray-600">
                  <i class="fas fa-cubes w-5"></i>
                  <span class="text-sm">Quantity:</span>
                  <span class="text-sm font-medium text-gray-900">{{
                    asset.quantity || 1
                    }}</span>
                </div> -->
              </div>

              <!-- Location Section -->
              <div class="space-y-2 pt-2 border-t border-gray-100">
                <div class="flex items-center space-x-2 text-gray-600">
                  <i class="fas fa-map-marker-alt w-5"></i>
                  <span class="text-sm">Location:</span>
                  <span class="text-sm font-medium text-gray-900">{{
                    asset.location || "Not Specified"
                  }}</span>
                </div>
                <div class="flex items-center space-x-2 text-gray-600">
                  <i class="fas fa-calendar-alt w-5"></i>
                  <span class="text-sm">Receipt Date:</span>
                  <span class="text-sm font-medium text-gray-900">{{
                    asset.date | date : "mediumDate"
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="currentPage < totalPages - 1" class="text-center mt-4">
          <button
            (click)="loadMoreAssets()"
            class="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            Load More Assets
          </button>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <div class="p-4 border-t bg-gray-50 rounded-b-xl">
      <div class="text-center">
        <button
          (click)="closeDialog()"
          class="px-6 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</dialog>

<!-- Summary Modal -->
<dialog
  #summaryDialog
  class="rounded-xl shadow-2xl p-0 w-full max-w-2xl mx-auto backdrop:bg-gray-900/50"
>
  <div class="bg-white rounded-xl">
    <div
      class="p-6 border-b bg-gradient-to-r from-green-600 to-green-800 rounded-t-xl"
    >
      <div class="flex justify-between items-center">
        <h3 class="text-2xl font-semibold text-white">Review Your Selection</h3>
        <button
          (click)="closeSummaryDialog()"
          class="text-white hover:text-gray-200 transition-colors"
        >
          <i class="fas fa-times text-xl"></i>
        </button>
      </div>
    </div>

    <div class="p-6">
      <div class="space-y-6">
        <div>
          <h4 class="font-medium text-gray-800 mb-3">Selected Assets</h4>
          <div class="space-y-3">
            <div
              *ngFor="let asset of selectedAssets"
              class="flex items-center justify-between p-3 bg-gray-50 rounded-lg"
            >
              <div class="flex items-center space-x-3">
                <i
                  [class]="
                    'fas ' + getAssetIcon(asset.assertType) + ' text-gray-600'
                  "
                ></i>
                <div>
                  <p class="font-medium text-gray-800">{{ asset.assetDisc }}</p>
                  <p class="text-sm text-gray-500">{{ asset.assertType }}</p>
                </div>
              </div>
              <button
                (click)="removeAsset(asset)"
                class="text-red-500 hover:text-red-600"
              >
                <i class="fas fa-trash-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="p-6 border-t bg-gray-50 rounded-b-xl">
      <div class="flex justify-end space-x-4">
        <button
          (click)="closeSummaryDialog()"
          class="px-6 py-2 border rounded-lg hover:bg-gray-100 transition-colors"
        >
          Back
        </button>
        <button
          (click)="confirmSelection()"
          class="px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
        >
          Confirm Selection
        </button>
      </div>
    </div>
  </div>
</dialog>
