<div class="sidebar">
  <!-- <div class="logo-details">
    <i class="bx bxl-c-plus-plus"></i>
    <span class="logo_name"><img src="../../assets/img/logo.png" /></span> Test
  </div> -->
  <ul class="nav-links">
    <!-- <li *ngFor="let menuItem of getAccessibleMenuItems()">
      <a routerLink="{{ menuItem.path }}" routerLinkActive="active">
        <i class="bx bx-grid-alt"></i>
        <span class="links_name">{{ menuItem.title }}</span>
      </a>

    </li> -->
    <aside
      id="sidebar-multi-level-sidebar"
      class="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0 bg-dark"
      aria-label="Sidebar"
    >
      <div class="dark h-full px-3 py-4 overflow-y-auto text-gray-500">
        <ul class="space-y-2 font-medium">
          <li>
            <span class="logo_name"
              ><img src="../../assets/img/logo.png"
            /></span>
          </li>
        </ul>
        <ul class="space-y-2 font-medium">
          <li *ngFor="let menuItem of getAccessibleMenuItems()">
            <!-- <a routerLink="{{ menuItem.path }}" routerLinkActive="active">
        <img *ngIf="menuItem.logo" [src]="menuItem.logo" alt="{{ menuItem.title }} Logo" class="menu-item-logo">

        <i class="bx bx-grid-alt"></i>
        <span class="links_name">{{ menuItem.title }}</span>
      </a>  -->
            <a
              routerLink="{{ menuItem.path }}"
              *ngIf="menuItem.title != 'Asserts'"
              class="flex items-center p-2 text-gray-900 rounded-lg dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              <svg
                class="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 22 21"
              >
                <path
                  d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z"
                />
                <path
                  d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z"
                />
              </svg>
              <span class="ms-3">{{ menuItem.title }}</span>
            </a>
            <div *ngIf="menuItem.title == 'Asserts'">
              <button
                type="button"
                (click)="toggleAssertSubMenu()"
                class="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
              >
                <svg
                  class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 18 21"
                >
                  <path
                    d="M15 12a1 1 0 0 0 .962-.726l2-7A1 1 0 0 0 17 3H3.77L3.175.745A1 1 0 0 0 2.208 0H1a1 1 0 0 0 0 2h.438l.6 2.255v.019l2 7 .746 2.986A3 3 0 1 0 9 17a2.966 2.966 0 0 0-.184-1h2.368c-.118.32-.18.659-.184 1a3 3 0 1 0 3-3H6.78l-.5-2H15Z"
                  />
                </svg>
                <span
                  class="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap"
                  >Assets</span
                >
                <svg
                  class="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <ul
                id="dropdown-example"
                class="py-2 space-y-2"
                *ngIf="isAssertSubMenuVisible"
              >
                <li>
                  <a
                    href="/assert"
                    class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                    >Assets List</a
                  >
                </li>
                <li>
                  <a
                    href="/assert"
                    class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                    >Inspections</a
                  >
                </li>
                <li>
                  <a
                    href="/checks"
                    class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                    >Checks</a
                  >
                </li>
                <li>
                  <a
                    href="/assettransfer"
                    class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                    >Internal Movement</a
                  >
                </li>
                <li>
                  <a
                    href="/Statistics"
                    class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                    >Assets Report</a
                  >
                </li>


                <li *ngIf="role === 'HEADADMIN' || role === 'DEPUTYHEADADMIN' || role === 'SECRETARY'"> 
                  <a
                    href="/assert/assetregister"
                    class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                    >Assets Register</a
                  >
                </li>



              </ul>
            </div>
          </li>
        </ul>
      </div>
    </aside>
  </ul>
</div>
