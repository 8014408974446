import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Province } from '../component/asset-per-station-stats/asset-per-station-stats.component';
import { Station } from '../component/asset-per-station-stats/asset-per-station-stats.component';
import { AssetItem } from '../component/asset-per-station-stats/asset-per-station-stats.component';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {
  private _baseUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) { }


  getStats(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/assert/stats`);
  }
  getAllAssets(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/assert/getall`);
  }
  //pdf download
  getAllTotalAssets(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/assert/allasserts`);
  }
  getAllUserAsserts(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/assert/UserAssertsAll`);
  }

  getUserAssertsGroupedByStations(params?:string): Observable<any> {
    if(params){
      return this.http.get<any[]>(`${environment.apiUrl}/assert/UserAssertsAllGroupedByStation`+'?movable='+true);
    }else{
    return this.http.get<any[]>(`${environment.apiUrl}/assert/UserAssertsAllGroupedByStation`);
    }
  }
  // getMovableAsserts(): Observable<any> {
    // return this.http.get<any[]>(`${environment.apiUrl}/assert/UserAssertsAllGroupedByStation`);

  // }

  getStationStats(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/station/stats`);
  }



  getAllStationStats(): Observable<any> {
    return this.http.get<any[]>(`${environment.apiUrl}/station/stationsStat`);
  }

  getToken(): Observable<any> {
    return this.http.get<{ token: string }>(`${environment.apiUrl}/assert/statsToken`);
  }

  downloadPdf(): Observable<Blob> {
    return this.getToken().pipe(
      switchMap(tokenData => {
        const token = tokenData.token;
        return this.http.get(`${environment.apiUrl}/api/v1/jasper/pdf/asset?token=${token}`, { responseType: 'blob' });
      })
    );
  }

  getProvinces(): Observable<Province[]> {
    return this.http.get<Province[]>(`${environment.apiUrl}/provinces/all`);
  }

  getStationsByProvince(provinceId: number): Observable<Station[]> {
    return this.http.get<Station[]>(`${environment.apiUrl}/station/by-province/${provinceId}`);
  }
  getMovableAssert(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/assert/movable`);
  }

  getStationsByProvinceNew(provinceId: number): Observable<Station[]> {
    return this.http.get<Station[]>(`${this._baseUrl}/stations/province/${provinceId}`);
  }

  getAssetsByStation(stationId: number, page: number = 0, size: number = 10): Observable<{content: AssetItem[], totalPages: number, totalElements: number}> {
    return this.http.get<{content: AssetItem[], totalPages: number, totalElements: number}>(`${this._baseUrl}/assert/getByStation?stationId=${stationId}&page=${page}&size=${size}`);
  }
}
