import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { StatisticsService } from 'src/app/statistics/statistics.service';
import { Chart, ChartConfiguration, registerables, TooltipItem } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import autoTable from 'jspdf-autotable';
import jsPDF from 'jspdf';

Chart.register(...registerables, DataLabelsPlugin);

interface AssetStat {
  name: string;
  total: number;
}

export interface Station {
  station_id: number;
  stationName: string;
  province: {
    id: number;
    name: string;
    stationId: number | null;
  };
}

interface StationAssetStats {
  totalAsserts: number;
  totalFixedAsserts: number;
  totalCurrentAsserts: number;
  totalMovableAsserts: number;
  assetsStats: AssetStat[];
}

interface StationItemStat {
  stationId: number;
  name: string;
  total: number;
  assetsStats: StationAssetStats;
}

interface StationStatsResponse {
  totalStations: number;
  stationItemStats: StationItemStat[];
}

export interface AssetItem {
  id: number;
  date: Date;
  assetDisc: string;
  assetNumber: string;
  movable: boolean;
  serialNumber: string;
  invoiceNumber: string;
  assertType: string;
  location: string;
  officeLocation: {
    id: number;
    name: string;
  };
  initialRemarks: string;
  status?: 'Active' | 'Inactive';
  isMovable?: boolean;
  type?: string;
  name?: string;
}

export interface Province {
  id: number;
  name: string;
}

@Component({
  selector: 'app-asset-per-station-stats',
  templateUrl: './asset-per-station-stats.component.html',
  styleUrls: ['./asset-per-station-stats.component.css'],
})
export class AssetPerStationStatsComponent implements OnInit {
  @ViewChild('assetDialog') assetDialog!: ElementRef<HTMLDialogElement>;
  @ViewChild('summaryDialog') summaryDialog!: ElementRef<HTMLDialogElement>;
  @ViewChild('assetTypeChartCanvas') assetTypeChartCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('bubbleChartCanvas') bubbleChartCanvas!: ElementRef<HTMLCanvasElement>;

  private horizontalBarChart: Chart | null = null;
  private donutChart: Chart<'doughnut', number[], string> | null = null;
  private bubbleChart: Chart | null = null;
  private assetTypeChart: Chart | null = null;

  stationStats: StationStatsResponse = {
    totalStations: 0,
    stationItemStats: []
  };

  selectedProvince: string = '';
  selectedAssets: AssetItem[] = [];
  filteredAssets: AssetItem[] = [];

  provinces: Province[] = [];
  stations: Station[] = [];
  selectedStation: Station | null = null;

  assets: any[] = [];
//pushed
  movableAssetsCount: number = 0;
  immovableAssetsCount: number = 0;

  // Pagination properties
  currentPage = 0;
  pageSize = 10;
  totalPages = 0;
  totalElements = 0;
  assetList: any =[]
;
roles:any=[];
  totalAssertsList: any[];
  movableAssets: any[];
  userAssertsGroupedByStations: any;
  constructor(private statisticsService: StatisticsService) {}

  ngOnInit() {
    this.loadStationStats();
    this.loadProvinces();
    this.getTotalAssert();
    this.getAllUserAsserts();
    this.getAllMovableAsserts();
    this.roles = JSON.parse(localStorage.getItem('roles')|| '');
    console.log(typeof(this.roles),this.roles == 'ADMINOFFICER',)

  }

  getAllUserAsserts(){
    this.statisticsService.getUserAssertsGroupedByStations().subscribe({
      next: (data) => {
       this.userAssertsGroupedByStations = data;
      },
      error: (error) => {
        console.error('Error fetching all assets', error);
      }
    })
  }

  loadAllAssets(){
    this.statisticsService.getAllAssets().subscribe({
      next: (data) => {
        this.assetList = data;
        console.log(this.assetList)
      },
      error: (error) => {
        console.error('Error fetching all assets', error);
      }
    })
  }

  loadStationStats() {
    this.statisticsService.getStationStats().subscribe({
      next: (data: StationStatsResponse) => {
        this.stationStats = data;

        // Calculate movable and immovable assets
        this.movableAssetsCount = this.stationStats.stationItemStats.reduce((sum, station) =>
          sum + (station.assetsStats.totalMovableAsserts || 0), 0);

        this.immovableAssetsCount = this.stationStats.stationItemStats.reduce((sum, station) =>
          sum + ((station.assetsStats.totalAsserts || 0) - (station.assetsStats.totalMovableAsserts || 0)), 0);

        this.initializeCharts();
        this.initializeAssetTypeChart();
      },
      error: (error) => {
        console.error('Error fetching station stats', error);
      }
    });
  }

  loadProvinces() {
    this.statisticsService.getProvinces().subscribe({
      next: (provinces: Province[]) => {
        this.provinces = provinces;
        
        // Select the first province by default if provinces exist
        if (provinces.length > 0) {
          this.selectedProvince = provinces[0].name;
          this.selectProvince(this.selectedProvince);
        }
      },
      error: (error) => {
        console.error('Error fetching provinces', error);
        this.provinces = []; // Ensure provinces is an empty array on error
      }
    });
  }

  private initializeCharts() {
    try {
      // Destroy existing charts
      if (this.horizontalBarChart) {
        this.horizontalBarChart.destroy();
      }
      if (this.donutChart) {
        this.donutChart.destroy();
      }
      if (this.bubbleChart) {
        this.bubbleChart.destroy();
      }

      // Set all charts to null
      this.horizontalBarChart = null;
      this.donutChart = null;
      this.bubbleChart = null;

      // Initialize new charts
      setTimeout(() => {
        this.initializeHorizontalBarChart();
        this.initializeDonutChart();
        this.initializeBubbleChart();
      }, 0);
    } catch (error) {
      console.error('Error initializing charts:', error);
    }
  }

  private initializeHorizontalBarChart() {
    try {
      const canvas = document.getElementById('horizontalBarChart') as HTMLCanvasElement;
      if (!canvas) {
        console.error('horizontalBarChart canvas not found');
        return;
      }

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        console.error('Failed to get 2D context for horizontalBarChart');
        return;
      }

      if (!this.stationStats?.stationItemStats?.length) {
        console.warn('No station stats data available for horizontalBarChart');
        return;
      }

      const sortedStats = [...this.stationStats.stationItemStats]
        .sort((a, b) => (b.total || 0) - (a.total || 0))
        .slice(0, 10);

      this.horizontalBarChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: sortedStats.map(station => station.name || 'Unknown Station'),
          datasets: [{
            label: 'Total Assets',
            data: sortedStats.map(station => station.total || 0),
            backgroundColor: 'rgba(59, 130, 246, 0.6)',
            borderColor: 'rgba(59, 130, 246, 0.8)',
            borderWidth: 1,
            borderRadius: 4,
            maxBarThickness: 40
          }]
        },
        options: {
          indexAxis: 'y',
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: {
              left: 10,
              right: 10,
              top: 0,
              bottom: 0
            }
          },
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              titleColor: 'white',
              bodyColor: 'rgba(255, 255, 255, 0.8)'
            }
          },
          scales: {
            x: {
              beginAtZero: true,
              grid: {
                display: false
              },
              ticks: {
                color: 'rgba(0, 0, 0, 0.6)',
                font: {
                  size: 10
                }
              }
            },
            y: {
              grid: {
                display: false
              },
              ticks: {
                color: 'rgba(0, 0, 0, 0.6)',
                font: {
                  size: 10
                }
              }
            }
          }
        }
      });
    } catch (error) {
      console.error('Error initializing horizontal bar chart:', error);
    }
  }

  private initializeDonutChart() {
    try {
      const canvas = document.getElementById('donutChart') as HTMLCanvasElement;
      if (!canvas) {
        console.error('donutChart canvas not found');
        return;
      }

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        console.error('Failed to get 2D context for donutChart');
        return;
      }

      if (!this.stationStats?.stationItemStats?.length) {
        console.warn('No station stats data available for donutChart');
        return;
      }

      const movableAssets = this.stationStats.stationItemStats.reduce((total, station) =>
        total + (station.assetsStats.totalMovableAsserts || 0), 0);
      const immovableAssets = this.stationStats.stationItemStats.reduce((total, station) =>
        total + (station.assetsStats.totalFixedAsserts || 0), 0);

      const total = movableAssets + immovableAssets;
      if (total === 0) {
        console.warn('Total assets is zero, skipping donut chart');
        return;
      }

      this.donutChart = new Chart<'doughnut', number[], string>(ctx, {
        type: 'doughnut',
        data: {
          labels: ['Movable Assets', 'Immovable Assets'],
          datasets: [{
            data: [movableAssets, immovableAssets],
            backgroundColor: [
              'rgba(59, 130, 246, 0.8)',  // Blue for Movable
              'rgba(234, 88, 12, 0.8)'    // Orange for Immovable
            ],
            borderColor: [
              'rgb(59, 130, 246)',
              'rgb(234, 88, 12)'
            ],
            borderWidth: 1
          }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: {
              left: 0,
              right: 0
            }
          },
          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                boxWidth: 20,
                usePointStyle: true,
                pointStyle: 'circle'
              }
            },
            title: {
              display: true,
              text: 'Asset Type Distribution',
              font: {
                size: 16,
                weight: 'bold'
              }
            },
            tooltip: {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              padding: 12,
              callbacks: {
                label: (tooltipItem: TooltipItem<"doughnut">) => {
                  const value = tooltipItem.parsed;
                  const percentage = ((value / total) * 100).toFixed(1);
                  return `${tooltipItem.dataset.label}: ${value} (${percentage}%)`;
                }
              }
            }
          }
        }
      });
    } catch (error) {
      console.error('Error creating donutChart:', error);
    }
  }

  private initializeBubbleChart() {
    try {
      const canvas = document.getElementById('bubbleChart') as HTMLCanvasElement;
      if (!canvas) {
        console.error('bubbleChart canvas not found');
        return;
      }

      const ctx = canvas.getContext('2d');
      if (!ctx) {
        console.error('Failed to get 2D context for bubbleChart');
        return;
      }

      this.bubbleChart = new Chart(ctx, {
        type: 'bubble',
        data: {
          datasets: [{
            label: 'Asset Distribution',
            data: this.stationStats.stationItemStats.map((stat, index) => ({
              x: index,
              y: stat.total,
              r: Math.max(5, stat.assetsStats.totalAsserts * 3), // Ensure minimum bubble size
            })),
            backgroundColor: this.stationStats.stationItemStats.map((_, index) =>
              this.getColorForIndex(index)),
            borderColor: this.stationStats.stationItemStats.map((_, index) =>
              this.getColorForIndex(index)),
            borderWidth: 1,
            hoverBackgroundColor: this.stationStats.stationItemStats.map((_, index) =>
              this.getColorForIndex(index) + '80'), // Slightly transparent on hover
          }]
        },
        options: {
          responsive: true,
          layout: {
            padding: 10
          },
          plugins: {
            title: {
              display: true,
              text: 'Asset Distribution by Station',
              font: {
                size: 16,
                weight: 'bold'
              }
            },
            tooltip: {
              backgroundColor: 'rgba(0,0,0,0.7)',
              titleFont: {
                size: 14,
                weight: 'bold'
              },
              bodyFont: {
                size: 12
              },
              callbacks: {
                label: (tooltipItem: TooltipItem<"bubble">) => {
                  const stat = this.stationStats.stationItemStats[tooltipItem.dataIndex];
                  return [
                    `Station: ${stat.name}`,
                    `Total Assets: ${tooltipItem.parsed.y}`,
                    `Movable Assets: ${stat.assetsStats.totalMovableAsserts}`,
                    `Immovable Assets: ${stat.assetsStats.totalFixedAsserts}`
                  ];
                }
              }
            },
            datalabels: {
              display: false // Hide labels by default
            } as any
          },
          scales: {
            x: {
              title: {
                display: true,
                text: 'Station Index'
              },
              grid: {
                display: false
              }
            },
            y: {
              title: {
                display: true,
                text: 'Total Assets'
              },
              grid: {
                color: 'rgba(0,0,0,0.1)'
              }
            }
          }
        } as any
      });
    } catch (error) {
      console.error('Error creating bubbleChart:', error);
    }
  }

  private initializeAssetTypeChart() {
    if (this.assetTypeChartCanvas) {
      const assetTypes = this.getAssetTypeDistribution();

      // Prepare data for pie chart
      const labels = Object.keys(assetTypes);
      const data = Object.values(assetTypes);

      // Generate colors for each asset type
      const backgroundColors = labels.map((_, index) => this.getColorForIndex(index));

      // this.assetTypeChart = new Chart(this.assetTypeChartCanvas.nativeElement, {
      //   type: 'pie',
      //   data: {
      //     labels: labels,
      //     datasets: [{
      //       data: data,
      //       backgroundColor: backgroundColors,
      //       hoverBackgroundColor: backgroundColors.map(color => this.adjustColorBrightness(color, 1.2))
      //     }]
      //   },
      //   options: {
      //     responsive: true,
      //     plugins: {
      //       title: {
      //         display: true,
      //         text: 'Asset Type Distribution'
      //       },
      //       tooltip: {
      //         callbacks: {
      //           label: (context) => {
      //             const label = context.label || '';
      //             const value = context.parsed || 0;
      //             const total = context.dataset.data.reduce((a, b) => a + b, 0);
      //             const percentage = ((value / total) * 100).toFixed(2);
      //             return `${label}: ${value} (${percentage}%)`;
      //           }
      //         }
      //       },
      //       datalabels: {
      //         color: 'white',
      //         font: {
      //           weight: 'bold'
      //         },
      //         formatter: (value, context) => {
      //           // const total = context.dataset.data.reduce((a, b)=>a+b);
      //           // const percentage = ((value / total) * 100).toFixed(2);
      //           const percentage = 60;
      //           return `${percentage}%`;
      //         }
      //       }
      //     }
      //   }
      // } as ChartConfiguration<'pie', number[], string>);
    }
  }

  // Helper method to generate consistent colors
  private getColorForIndex(index: number): string {
    const colors = [
      'rgba(54, 162, 235, 0.6)',   // Blue
      'rgba(255, 99, 132, 0.6)',   // Red
      'rgba(75, 192, 192, 0.6)',   // Green
      'rgba(255, 206, 86, 0.6)',   // Yellow
      'rgba(153, 102, 255, 0.6)',  // Purple
      'rgba(255, 159, 64, 0.6)',   // Orange
      'rgba(199, 199, 199, 0.6)',  // Grey
    ];
    return colors[index % colors.length];
  }

  // Helper method to adjust color brightness
  private adjustColorBrightness(color: string, factor: number): string {
    // Convert hex to RGB
    const hex = color.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    // Adjust brightness
    const adjustedR = Math.min(255, Math.round(r * factor));
    const adjustedG = Math.min(255, Math.round(g * factor));
    const adjustedB = Math.min(255, Math.round(b * factor));

    // Convert back to hex
    return `#${adjustedR.toString(16).padStart(2, '0')}${adjustedG.toString(16).padStart(2, '0')}${adjustedB.toString(16).padStart(2, '0')}`;
  }

  // Modify getAssetTypeDistribution to return an object with asset types and their counts
  private getAssetTypeDistribution(): { [key: string]: number } {
    const typeDistribution: { [key: string]: number } = {};

    this.assets.forEach(asset => {
      const type = asset.assertType || 'Unknown';
      typeDistribution[type] = (typeDistribution[type] || 0) + 1;
    });

    return typeDistribution;
  }

  openAssetDialog() {
    this.loadAllAssets();
    console.log('Opening asset dialog', this.assetDialog);
    if (this.assetDialog && this.assetDialog.nativeElement) {
      try {
        this.assetDialog.nativeElement.showModal();
      } catch (error) {
        console.error('Error opening dialog:', error);
      }
    } else {
      console.warn('Asset dialog reference is not available');
    }
  }

  closeDialog() {
    this.resetSelections();
    this.assetDialog.nativeElement.close();
  }

  resetSelections() {
    this.selectedProvince = '';
    this.selectedAssets = [];
    this.filteredAssets = [];
  }
  getTotalAssert(){
    this.statisticsService.getAllTotalAssets().subscribe({
      next: (data: any[]) => {
        this.totalAssertsList = data;
     console.log(data,"total@@@@@@@@@@@@@@@@@#############")
      },
      error: (error) => {
        console.error('Error fetching movable assert', error);
      }
    });
  }
  downloadTotalAsset(){
    const doc = new jsPDF();
  
      const logoImg = new Image();
      logoImg.src = 'assets/img/logo.png';
      logoImg.onload = () => {
        const logoWidth = 20;
        const logoHeight = 20;
        const logoX = 10;
        const logoY = 8;
        doc.addImage(logoImg, 'PNG', logoX, logoY, logoWidth, logoHeight);
  
        const title = 'Judicial Service Commission';
        const subtitle = 'Total Asserts';
  
        doc.setFontSize(16);
        const pageWidth = doc.internal.pageSize.width;
        const titleX = (pageWidth - doc.getTextWidth(title)) / 2;
        doc.text(title, titleX, 20);
  
        doc.setFontSize(14);
        const subtitleX = (pageWidth - doc.getTextWidth(subtitle)) / 2;
        doc.text(subtitle, subtitleX, 30);
  
        // doc.setFontSize(12);
        // const stationNameX = (pageWidth - doc.getTextWidth(stationName)) / 2;
        // doc.text(stationName, stationNameX, 38);
  //ok
        const footerText = 'World class justice system @ 2024';
        doc.setFontSize(10);
        const footerTextX = (pageWidth - doc.getTextWidth(footerText)) / 2;
        const footerTextY = doc.internal.pageSize.height - 10;
  
        doc.text(footerText, footerTextX, footerTextY);
  
        const columns = [
          'Date',
          'Initial Remarks',
          'Asset',
          'Serial No',
          'Invoice No',
          'Location',
          'Asset Type',
          
          
        ];
  
        const rows: any[][] = [];
  
        this.totalAssertsList.forEach((item:any) => {
          let inspectionsText = '-';
          if (item.inspections && item.inspections.length > 0) {
            inspectionsText = item.inspections
              .map(
                (inspection: {
                  date: string | number | Date;
                  remarks: any;
                  lastModifiedBy: any;
                }) =>
                  `Date: ${this.formatDate(inspection.date)}\nRemarks: ${
                    inspection.remarks
                  }\nLast Modified By: ${inspection.lastModifiedBy}\n`
              )
              .join('\n\n');
          }
  
          const row = [
            this.formatDate(item.date),
            item.initialRemarks,
            item.assetDisc,
            item.serialNumber,
            item.invoiceNumber,
            item.location,
            item.assertType,
           
          ];
          rows.push(row);
        });
  
        autoTable(doc, {
          startY: 45,
          head: [columns],
          body: rows,
          theme: 'grid',
          styles: {
            fontSize: 6,
            cellPadding: 3,
          },
          headStyles: {
            fillColor: [22, 160, 133],
            fontStyle: 'bold',
          },
          alternateRowStyles: {
            fillColor: [240, 240, 240],
          },
          columnStyles: {
            8: { cellWidth: 40 },
          },
          bodyStyles: {
            fontStyle: 'bold',
          },
        });
  
        doc.save('Total_Asset_List.pdf');
      };
    
  }

  downloadUserAssertGroupedByStation(){
    const doc = new jsPDF();

    const logoImg = new Image();
    logoImg.src = 'assets/img/logo.png';
    logoImg.onload = () => {
      const logoWidth = 20;
      const logoHeight = 20;
      const logoX = 10;
      const logoY = 8;
      doc.addImage(logoImg, 'PNG', logoX, logoY, logoWidth, logoHeight);

      const title = 'Judicial Service Commission';
      const subtitle = 'Asserts List Grouped By Stations';

      doc.setFontSize(18);
      const pageWidth = doc.internal.pageSize.width;
      const titleX = (pageWidth - doc.getTextWidth(title)) / 2;
      doc.text(title, titleX, 20);

      doc.setFontSize(14);
      const subtitleX = (pageWidth - doc.getTextWidth(subtitle)) / 2;
      doc.text(subtitle, subtitleX, 30);

      // Footer
      const footerText = 'World class justice system @ 2024';
      doc.setFontSize(10);
      const footerTextX = (pageWidth - doc.getTextWidth(footerText)) / 2;
      const footerTextY = doc.internal.pageSize.height - 10;
      doc.text(footerText, footerTextX, footerTextY);

      const columns = [
        'Date',
        'Initial Remarks',
        'Asset',
        'Serial No',
        'Invoice No',
        'Location',
        'Asset Type',
      ];

      let currentY = 45; 

      this.userAssertsGroupedByStations.forEach((group:any) => {
        doc.setFontSize(12);
        doc.text('Station Name:  ' + group.name, 10, currentY);
        currentY += 4; 

        const rows = group.asserts.map((item:any) => [
          this.formatDate(item.date),
          item.initialRemarks,
          item.assetDisc,
          item.serialNumber,
          item.invoiceNumber,
          item.location,
          item.assertType,
        ]);

        autoTable(doc, {
          startY: currentY,
          head: [columns],
          body: rows,
          theme: 'grid',
          styles: {
            fontSize: 6,
            cellPadding: 3,
          },
          headStyles: {
            fillColor: [22, 160, 133],
            fontStyle: 'bold',
          },
          alternateRowStyles: {
            fillColor: [240, 240, 240],
          },
          bodyStyles: {
            fontStyle: 'bold',
          },
          didDrawPage: function (data) {
            if (data.cursor) {
              currentY = data.cursor?.y + 10;
            }
          },
        });

        if (currentY > doc.internal.pageSize.height - 30) {
          doc.addPage();
          currentY = 20; 
        }
      });

      doc.save('AssertList_By_Stations.pdf');
    };
  }

    
  
  getAllMovableAsserts(){
  this.statisticsService.getUserAssertsGroupedByStations('movable').subscribe({
    next: (data: any[]) => {
      this.movableAssets = data;
   console.log(data,"MOVABLE#############")
    },
    error: (error) => {
      console.error('Error fetching movable assert', error);
    }
  });
}
  downloadMovableAsset(){
    const doc = new jsPDF();

    const logoImg = new Image();
    logoImg.src = 'assets/img/logo.png';
    logoImg.onload = () => {
      const logoWidth = 20;
      const logoHeight = 20;
      const logoX = 10;
      const logoY = 8;
      doc.addImage(logoImg, 'PNG', logoX, logoY, logoWidth, logoHeight);

      const title = 'Judicial Service Commission';
      const subtitle = 'Movable Asserts';

      doc.setFontSize(18);
      const pageWidth = doc.internal.pageSize.width;
      const titleX = (pageWidth - doc.getTextWidth(title)) / 2;
      doc.text(title, titleX, 20);

      doc.setFontSize(14);
      const subtitleX = (pageWidth - doc.getTextWidth(subtitle)) / 2;
      doc.text(subtitle, subtitleX, 30);

      // Footer
      const footerText = 'World class justice system @ 2024';
      doc.setFontSize(10);
      const footerTextX = (pageWidth - doc.getTextWidth(footerText)) / 2;
      const footerTextY = doc.internal.pageSize.height - 10;
      doc.text(footerText, footerTextX, footerTextY);

      const columns = [
        'Date',
        'Initial Remarks',
        'Asset',
        'Serial No',
        'Invoice No',
        'Location',
        'Asset Type',
      ];

      let currentY = 45; 

      this.movableAssets.forEach((group:any) => {
        doc.setFontSize(12);
        doc.text('Station Name:  ' + group.name, 10, currentY);
        currentY += 4; 

        const rows = group.asserts.map((item:any) => [
          this.formatDate(item.date),
          item.initialRemarks,
          item.assetDisc,
          item.serialNumber,
          item.invoiceNumber,
          item.location,
          item.assertType,
        ]);

        autoTable(doc, {
          startY: currentY,
          head: [columns],
          body: rows,
          theme: 'grid',
          styles: {
            fontSize: 6,
            cellPadding: 3,
          },
          headStyles: {
            fillColor: [22, 160, 133],
            fontStyle: 'bold',
          },
          alternateRowStyles: {
            fillColor: [240, 240, 240],
          },
          bodyStyles: {
            fontStyle: 'bold',
          },
          didDrawPage: function (data) {
            if (data.cursor) {
              currentY = data.cursor?.y + 10;
            }
          },
        });

        if (currentY > doc.internal.pageSize.height - 30) {
          doc.addPage();
          currentY = 20; 
        }
      });

      doc.save('Movable_Asserts_By_Station.pdf');
    };

       
  }
  formatDate(inputDate: string | number | Date) {
    const dateObj = new Date(inputDate);
    const day = String(dateObj.getDate()).padStart(2, '0');
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = dateObj.getFullYear();
    return `${day}/${month}/${year}`;
  }
  selectProvince(provinceName: string) {
    this.selectedProvince = provinceName;
    this.filteredAssets = [];
    this.selectedAssets = [];
    this.stations = []; // Reset stations

    // Find the province ID
    const selectedProvinceObj = this.provinces.find(p => p.name === provinceName);
    if (!selectedProvinceObj) {
      console.error('Province not found');
      return;
    }

    // Fetch stations for the selected province
    this.statisticsService.getStationsByProvince(selectedProvinceObj.id).subscribe({
      next: (stations: Station[]) => {
        this.stations = stations;
        
        // Select the first station by default if stations exist
        if (stations.length > 0) {
          this.selectStation(stations[0]);
        }
      },
      error: (error) => {
        console.error('Error fetching stations', error);
        this.stations = []; // Ensure stations is an empty array on error
      }
    });
  }

  selectStation(station: Station | null) {
    this.selectedStation = station;
    this.filteredAssets = [];
    this.selectedAssets = [];
    this.currentPage = 0; // Reset to first page

    if (!station) {
      return;
    }

    // Fetch assets for the selected station with pagination
    this.statisticsService.getAssetsByStation(station.station_id, this.currentPage, this.pageSize).subscribe({
      next: (response) => {
        this.filteredAssets = response.content;
        this.totalPages = response.totalPages;
        this.totalElements = response.totalElements;
      },
      error: (error) => {
        console.error('Error fetching assets', error);
        this.filteredAssets = [];
        this.totalPages = 0;
        this.totalElements = 0;
      }
    });
  }

  loadMoreAssets() {
    if (this.currentPage < this.totalPages - 1 && this.selectedStation) {
      this.currentPage++;
      this.statisticsService.getAssetsByStation(this.selectedStation.station_id, this.currentPage, this.pageSize).subscribe({
        next: (response) => {
          this.filteredAssets = [...this.filteredAssets, ...response.content];
        },
        error: (error) => {
          console.error('Error loading more assets', error);
        }
      });
    }
  }

  changePageSize(size: number) {
    this.pageSize = size;
    if (this.selectedStation) {
      this.selectStation(this.selectedStation);
    }
  }

  onCourtSelect() {
    if (!this.selectedProvince) {
      this.filteredAssets = [];
      return;
    }

    // Find the province ID
    const selectedProvinceObj = this.provinces.find(p => p.name === this.selectedProvince);
    if (!selectedProvinceObj) {
      console.error('Province not found');
      this.filteredAssets = [];
      return;
    }

    // Fetch stations for the selected province
    this.statisticsService.getStationsByProvince(selectedProvinceObj.id).subscribe({
      next: (stations: Station[]) => {
        this.stations = stations;
        
        // If you have a method to fetch assets by province, replace this with that method
        // For now, we'll reset filteredAssets and let the user select a specific station
        this.filteredAssets = [];
      },
      error: (error) => {
        console.error('Error fetching stations', error);
        this.filteredAssets = [];
      }
    });
  }

  isAssetSelected(asset: AssetItem) {
    return this.selectedAssets.some(a => a.id === asset.id);
  }

  toggleAsset(asset: AssetItem) {
    const index = this.selectedAssets.findIndex(a => a.id === asset.id);
    if (index === -1) {
      this.selectedAssets.push(asset);
    } else {
      this.selectedAssets.splice(index, 1);
    }
  }

  areAllAssetsSelected() {
    return this.filteredAssets.length > 0 &&
      this.filteredAssets.every(asset => this.isAssetSelected(asset));
  }

  toggleAllAssets() {
    if (this.areAllAssetsSelected()) {
      this.selectedAssets = [];
    } else {
      this.selectedAssets = [...this.filteredAssets];
    }
  }

  confirmSelection() {
    console.log('Selected assets:', this.selectedAssets);
    this.closeDialog();
  }

  getTotalAssets(): number {
    return this.stationStats.stationItemStats.reduce((total, station) =>
      total + station.assetsStats.totalAsserts, 0);
  }

  getAssetDistribution(): string {
    if (!this.stationStats || !this.stationStats.stationItemStats) {
      return '0%';
    }

    const totalFixedAssets = this.stationStats.stationItemStats.reduce((sum, station) =>
      sum + (station.assetsStats.totalFixedAsserts || 0), 0);
    const totalCurrentAssets = this.stationStats.stationItemStats.reduce((sum, station) =>
      sum + (station.assetsStats.totalCurrentAsserts || 0), 0);

    const totalAssets = totalFixedAssets + totalCurrentAssets;

    if (totalAssets === 0) {
      return '0%';
    }

    const fixedPercentage = (totalFixedAssets / totalAssets * 100).toFixed(1);
    const currentPercentage = (totalCurrentAssets / totalAssets * 100).toFixed(1);

    return `${fixedPercentage}% Fixed, ${currentPercentage}% Current`;
  }

  closeSummaryDialog() {
    this.summaryDialog.nativeElement.close();
  }

  getAssetIcon(type: string): string {
    const typeToIcon: { [key: string]: string } = {
      'Computer Equipment': 'fa-desktop',
      'Printing Equipment': 'fa-print',
      'Network Equipment': 'fa-network-wired',
      'Office Furniture': 'fa-chair',
      'Storage Equipment': 'fa-archive',
      'Electronic': 'fa-plug',
      'Furniture': 'fa-couch',
      // Add more mappings as needed
      'default': 'fa-box'
    };
    
    return typeToIcon[type] || typeToIcon['default'];
  }

  removeAsset(asset: AssetItem): void {
    const index = this.selectedAssets.findIndex(a => a.id === asset.id);
    if (index !== -1) {
      this.selectedAssets.splice(index, 1);
    }
  }

  onDialogClick(event: MouseEvent) {
    // Prevent the dialog from closing when clicking inside it
    event.stopPropagation();
  }
}
