<app-sidebar></app-sidebar>

<section class="home-section">
  <nav class="bg-white shadow-lg p-4 flex items-center justify-between">
    <!-- Sidebar and Greeting Section -->
    <div class="sidebar-button">
      <i class="bx bx-menu sidebarBtn"></i>
      <div>
        <h1 class="text-xl font-semibold text-left text-gray-800 font-serif">
          Hi, Welcome back!
        </h1>
      </div>
    </div>

    <!-- Search Box -->
    <div class="input-container">
      <input type="text" name="text" class="input" placeholder="search..." />
      <span class="icon">
        <svg
          width="19px"
          height="19px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <path
              opacity="1"
              d="M14 5H20"
              stroke="#000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              opacity="1"
              d="M14 8H17"
              stroke="#000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M21 11.5C21 16.75 16.75 21 11.5 21C6.25 21 2 16.75 2 11.5C2 6.25 6.25 2 11.5 2"
              stroke="#000"
              stroke-width="2.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              opacity="1"
              d="M22 22L20 20"
              stroke="#000"
              stroke-width="3.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </g>
        </svg>
      </span>
    </div>

    <!-- Profile and Logout Section -->
    <!-- <div class="flex items-center gap-4">
      <button class="text-gray-600 hover:text-gray-800 focus:outline-none">
        <i class="bx bx-chevron-down text-2xl"></i>
      </button>
      <button
        (click)="onLogOut()"
        class="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300"
      >
        Logout
      </button> -->
    <button class="Btn" (click)="onLogOut()">
      <div class="sign">
        <svg viewBox="0 0 512 512">
          <path
            d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"
          ></path>
        </svg>
      </div>

      <div class="text">Logout</div>
    </button>
  </nav>

  <!-- <nav>
    <div class="sidebar-button">
      <i class="bx bx-menu sidebarBtn"></i>
      <div>
        <h1 class="text-xl font-semibold text-left text-gray-800 font-serif">
               
         
Hi, Welcome back!
        </h1>
      </div>
    </div>
    <div class="search-box">
      <input type="text" placeholder="Search..." />
      <i class="bx bx-search"></i>
    </div>
    <div class="profile-details">
      <i class="bx bx-chevron-down"></i>
      <button (click)="onLogOut()">Logout</button>
    </div>
  </nav> -->

  <div class="home-content">
    <div class="container-fluid">
      <!-- ============================================================== -->
      <!-- Sales Cards  -->
      <!-- ============================================================== -->
      <div class="row">
        <!-- Column -->

        <!-- 
                <a routerLink="/inventory" routerLinkActive="active" class="col-md-6 col-lg-2 col-xlg-3"
                  [class.disabled]="(roles ==  'DEV'  ) ? null : true">


                  <div class="card card-hover">
                    <div class="box bg-info text-center">
                      <h1 class="font-light text-white">
                        <i class="pi pi-money-bill" style="font-size: 2rem"></i>
                      </h1>
                      <h6 class="text-white">Inventory</h6>
                    </div>
                  </div>

                </a>  -->

        <!-- 
                <div
          class="col-md-6 col-lg-4 col-xlg-3"
          routerLink="/purchase"
          routerLinkActive="true"
        >
          <div class="card card-hover">
            <div class="box bg-success text-center">
              <h1 class="font-light text-white">
                <i class="pi pi-truck" style="font-size: 2rem"></i>
              </h1>
              <h6 class="text-white">Purchase Request</h6>
            </div>
          </div>
        </div> -->
        <!--     
        <div
          class="col-md-6 col-lg-2 col-xlg-3"
          routerLink="/stockitemrequest"
          routerLinkActive="true"
        >
          <div class="card card-hover">
            <div class="box bg-warning text-center">
              <h1 class="font-light text-white">
                <i class="pi pi-money-bill" style="font-size: 2rem"></i>
              </h1>
              <h6 class="text-white">Stock Item Request</h6>
            </div>
          </div>
        </div>  -->

        <!-- 


         <a routerLink="/purchase" routerLinkActive="active" class="col-md-6 col-lg-2 col-xlg-3"
          [class.disabled]="(roles === 'PRINCIPAL_ADMIN' || roles == 'SYSADMIN' ) ? null : true">


          <div class="card card-hover">
            <div class="box bg-info text-center">
              <h1 class="font-light text-white">
                <i class="pi pi-money-bill" style="font-size: 2rem"></i>
              </h1>
              <h6 class="text-white">Purchase Request</h6>
            </div>
          </div>

        </a>  -->

        <!-- <a
          routerLink="/assert"
          routerLinkActive="active"
          class="col-md-4 col-lg-4 col-xlg-3"
          [hidden]="
            (roles === 'ADMIN' &&
              roles === 'SYSADMIN' &&
              roles === 'PRINCIPALADMIN' &&
              roles === 'ASSISTANT_ADMIN' &&
              roles === 'ADMINOFFICER' &&
              roles === 'AUDITOR' &&
              roles === 'AdminCS' &&
              roles === 'HEADADMIN') ||
            roles === 'SECRETARY'
          "
        >
          <div class="card card-hover">
            <div class="box bg-success text-center">
              <h1 class="font-light text-white">
                <i class="pi pi-money-bill" style="font-size: 2rem"></i>
              </h1>
              <h6 class="text-white">Asset Management</h6>
            </div>
          </div>
        </a> -->

        <a
          routerLink="/audit"
          routerLinkActive="active"
          class="col-md-4 col-lg-4 col-xlg-3 custom-card"
          [hidden]="roles !== 'AUDITOR' && roles !== 'SYSADMIN'"
        >
          <div class="card card-hover">
            <div class="box bg-success text-center">
              <h1 class="font-light text-white">
                <i class="pi pi-money-bill" style="font-size: 2rem"></i>
              </h1>
              <h6 class="text-white">AuditTrail</h6>
            </div>
          </div>
        </a>

        <!-- <a routerLink="/checks" routerLinkActive="active" class="col-md-4 col-lg-4 col-xlg-3 custom-card"
           [hidden]="(roles !== 'REGISTRAR' ) ">
          <div class="card card-hover">
            <div class="box bg-info text-center">
              <h1 class="font-light text-white">
                <i class="pi pi-money-bill" style="font-size: 2rem"></i>
              </h1>
              <h6 class="text-white">Checks</h6>
            </div>
          </div>
        </a> -->

        <!-- <a
          routerLink="/masterregister"
          routerLinkActive="active"
          class="col-md-4 col-lg-4 col-xlg-3 custom-card"
          [hidden]="
            roles !== 'PRINCIPALADMIN' &&
            roles !== 'HEADADMIN' &&
            roles === 'SECRETARY' &&
            roles !== 'AUDITOR' &&
            roles !== 'DEPUTYHEADADMIN' &&
            roles !== 'HEADADMIN'
          "
        >
          <div class="card card-hover">
            <div class="box bg-success text-center">
              <h1 class="font-light text-white">
                <i class="pi pi-money-bill" style="font-size: 2rem"></i>
              </h1>
              <h6 class="text-white">Master Asset Register</h6>
            </div>
          </div>
        </a> -->

        <!-- 
       <a routerLink="/officeb" routerLinkActive="active" class="col-md-4 col-lg-4 col-xlg-3 custom-card"
        [hidden]="(roles !== 'ASSISTANT_ADMIN' ) ">
       <div class="card card-hover">
         <div class="box bg-info text-center">
           <h1 class="font-light text-white">
             <i class="pi pi-money-bill" style="font-size: 2rem"></i>
           </h1>
           <h6 class="text-white">ASSISTANT_ADMIN</h6>
         </div>
       </div>
      </a> 
 -->

        <!-- <a
          routerLink="/masterregister"
          routerLinkActive="active"
          class="col-md-4 col-lg-4 col-xlg-3 custom-card"
          [hidden]="roles !== 'PRINCIPALADMIN' && roles === 'SECRETARY'"
        >
          <div class="card card-hover">
            <div class="box bg-success text-center">
              <h1 class="font-light text-white">
                <i class="pi pi-money-bill" style="font-size: 2rem"></i>
              </h1>
              <h6 class="text-white">Master Assert Register</h6>
            </div>
          </div>
        </a> -->

        <!--        
        <a routerLink="/management" routerLinkActive="active" class="col-md-4 col-lg-4 col-xlg-3 custom-card"
        >
        <div class="card card-hover">
         <div class="box bg-info text-center">
           <h1 class="font-light text-white">
             <i class="pi pi-money-bill" style="font-size: 2rem"></i>
           </h1>
           <h6 class="text-white">Project Management</h6>
         </div>
        </div>
        </a> -->

        <!-- <a
          routerLink="/assettransfer"
          routerLinkActive="active"
          class="col-md-4 col-lg-4 col-xlg-3 custom-card"
          [hidden]="
            roles !== 'ADMIN' &&
            roles !== 'ASSISTANT_ADMIN' &&
            roles !== 'PRINCIPAL_ADMIN' &&
            roles !== 'ADMINOFFICER' &&
            roles === 'SECRETARY' &&
            roles !== 'AUDITOR' &&
            roles !== 'DEPUTYHEADADMIN'
          "
        >
          <div class="card card-hover">
            <div class="box bg-success text-center">
              <h1 class="font-light text-white">
                <i class="pi pi-money-bill" style="font-size: 2rem"></i>
              </h1>
              <h6 class="text-white">asset Movement</h6>
            </div>
          </div>
        </a> -->

        <!-- <a routerLink="/assertdepression" routerLinkActive="active" class="col-md-4 col-lg-4 col-xlg-3 custom-card"
        [class.disabled]="(roles!== 'PRINCIPALADMIN' || roles!== 'SECRETARY')? null : true">
       <div class="card card-hover">
         <div class="box bg-info text-center">
           <h1 class="font-light text-white">
             <i class="pi pi-money-bill" style="font-size: 2rem"></i>
           </h1>
           <h6 class="text-white">Assert Depression</h6>
         </div>
       </div>
      </a>  -->

        <!-- <a
          routerLink="/Statistics"
          routerLinkActive="active"
          class="col-md-4 col-lg-4 col-xlg-3"
          [hidden]="
            roles !== 'ADMIN' &&
            roles !== 'REGISTRAR' &&
            roles === 'SECRETARY' &&
            roles !== 'PRINCIPAL_ADMIN' &&
            roles !== 'ADMINOFFICER' &&
            roles !== 'AUDITOR' &&
            roles !== 'HEADADMIN'
          "
        >
          <div class="card card-hover">
            <div class="box bg-success text-center">
              <h1 class="font-light text-white">
                <i class="pi pi-money-bill" style="font-size: 2rem"></i>
              </h1>
              <h6 class="text-white">Reports</h6>
            </div>
          </div>
        </a> -->

        <!-- <a routerLink="/purchase" routerLinkActive="active" class="col-md-6 col-lg-2 col-xlg-3"
        [class.disabled]="(roles === 'PRINCIPAL_ADMIN' || roles == 'SYSADMIN' ) ? null : true">


        <div class="card card-hover">
          <div class="box bg-info text-center">
            <h1 class="font-light text-white">
              <i class="pi pi-money-bill" style="font-size: 2rem"></i>
            </h1>
            <h6 class="text-white">Purchase Request</h6>
          </div>
        </div>

      </a> -->
      </div>

      <!-- 
      <div class="tab-pane fade show active" id="users">
  <div class="mb-3 float-right"> -->
      <!-- <div class="btn-group mr-2">


      <form class="form-inline my-2 my-lg-0 justify-content-center">
        <input name="searchTerm" #searchInput="ngModel" class="form-control mr-sm-2" ngModel
        (ngModelChange)="searchUsers(searchInput.value)" type="search" placeholder="Search users...">
     </form>


  </div>      -->

      <div *ngIf="getCurrentUserRoleLogin() !== 'SYSADMIN'">
        <app-asset-per-station-stats></app-asset-per-station-stats>
      </div>

      <div
        Id="searchusers"
        *ngIf="getCurrentUserRoleLogin() === 'SYSADMIN'"
        class="sales-boxes"
      >
        <p-table
          #dt
          [value]="users"
          styleClass="p-datatable-gridlines"
          [paginator]="true"
          [rows]="5"
          [showCurrentPageReport]="true"
          [tableStyle]="{ 'min-width': '50rem' }"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [rowsPerPageOptions]="[5, 10, 20]"
          [globalFilterFields]="[
            'firstName',
            'lastName',
            'email',
            'roleName',
            'permissions'
          ]"
        >
          <ng-template pTemplate="caption">
            <div>
              <h5 class="m-0">Search Users</h5>
              <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  (input)="dt.filterGlobal(filterValue($event), 'contains')"
                  placeholder="Search..."
                />
              </span>
            </div>
          </ng-template>
          <div class="card flex justify-content-center">
            <button
              pButton
              pRipple
              label="Add Station"
              class="p-button-success"
            ></button>
          </div>
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 25%">Name</th>
              <th style="width: 20%">Email</th>
              <th style="width: 25%">Role</th>
              <th style="width: 15%">Permission</th>
              <th style="width: 15%">Station</th>
              <th style="width: 15%">Action</th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-product>
            <tr>
              <td>{{ product.firstName }} {{ product.lastName }}</td>
              <td>{{ product.email }}</td>
              <td>{{ product.roleName }}</td>
              <td
                [innerHTML]="product.permissions.split(',').join('<br>')"
              ></td>
              <td>{{ product.station }}</td>
              <td>
                <p-button
                  icon="pi pi-user-edit"
                  class="small-button"
                  (click)="editUseraction(product)"
                ></p-button
                >&NonBreakingSpace;

                <p-button
                  icon="pi pi-times"
                  lass="small-button"
                  (click)="deleteUser(product)"
                  styleClass="p-button-rounded p-button-danger font-size:0.5rem"
                ></p-button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="paginatorleft">
            <p-button
              type="button"
              icon="pi pi-plus"
              styleClass="p-button-text"
            ></p-button>
          </ng-template>
          <ng-template pTemplate="paginatorright">
            <p-button
              type="button"
              icon="pi pi-cloud"
              styleClass="p-button-text"
            ></p-button>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>

  <p-dialog
    header="Inspection"
    [(visible)]="displayStation"
    [style]="{ width: '50vw' }"
  >
    <form [formGroup]="StationForm" (ngSubmit)="onSubmitStation()">
      <div class="container">
        <div class="pform row g-3">
          <div class="col-sm-8">
            <input
              type="text"
              pInputText
              placeholder="Station"
              formControlName="station"
              class="form-control"
              required
            />
          </div>
        </div>

        <button type="submit" pButton label="Submit"></button>
      </div>
    </form>
  </p-dialog>
  <!-- </section> -->
</section>
